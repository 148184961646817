/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { PageHeader, Space, View } from '../Widgets';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import * as Styles from './Styles/contact.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { GlobalConfig } from '../Configuration';

interface IContactPage {}

const ContactPage: React.FC<IContactPage> = () => {
    const intl = useIntl();

    const captchaToken = useRef<string | undefined>();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'contact.pageTitle' })}
                description={intl.formatMessage({
                    id: 'contact.metaDescription',
                })}
                lang={intl.locale}
            />

            <PageHeader
                title={intl.formatMessage({
                    id: 'contact.bannerLabel',
                })}
            />

            <Space height={24} />

            <Container fluid>
                <Row>
                    <Col md={4} xs={12}>
                        <StaticImage
                            src={'../Resources/Images/Contact/leftImage.jpg'}
                            className={Styles.leftImage}
                            style={{ objectFit: 'cover' }}
                            alt=""
                        />
                    </Col>
                    <Col md={8} xs={12} className="pageContent">
                        <h1>{intl.formatMessage({ id: 'contact.title' })}</h1>

                        <Space height={32} />

                        <p>
                            {intl.formatMessage({
                                id: 'contact.content1',
                            })}
                        </p>

                        <Space height={32} />

                        <View className="leftAligned">
                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({
                                        id: 'contact.name',
                                    })}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={intl.formatMessage({
                                        id: 'contact.nameHint',
                                    })}
                                    maxLength={128}
                                />
                            </Form.Group>

                            <Space height={6} />

                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({
                                        id: 'contact.emailAddress',
                                    })}
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={intl.formatMessage({
                                        id: 'contact.emailAddressHint',
                                    })}
                                    maxLength={256}
                                />
                            </Form.Group>

                            <Space height={6} />

                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({
                                        id: 'contact.message',
                                    })}
                                </Form.Label>
                                <Form.Control as="textarea" rows={8} />
                            </Form.Group>

                            <Space height={6} />

                            <View style={{ alignItems: 'flex-end' }}>
                                <Button
                                    variant="primary"
                                    className={Styles.submitButton}>
                                    {intl.formatMessage({
                                        id: 'general.submit',
                                    })}
                                </Button>
                            </View>
                        </View>
                    </Col>
                </Row>
            </Container>

            <Space height={24} />

            <GoogleReCaptcha
                onVerify={token => {
                    captchaToken.current = token;
                }}
            />
        </Layout>
    );
};

export default ContactPage;
